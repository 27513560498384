import React from 'react'
import {Hearts}	 from 'react-loader-spinner'
import { LoadingContainer  } from './SectionElements'

const Loading = () => {
    return (
        <LoadingContainer background-color='black'>
        <div align='center'>
           
            <Hearts color="#C6AAF3" 
            height={300}
                width={300}
                timeout={3000}
               
                ariaLabel="hearts-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}

            />
           

        </div>
        </LoadingContainer >
    )
}

export default Loading