
import React from 'react';
import InfoSec from '../components/InfoSec';
import { VRPT,Chatbot, SASERedesign, JobTracker,Marilartoon} from '../components/InfoSec/Data';
import {SectionContainer,MainHeading} from '../components/SectionElements';
import '../App.css';

const Portfolio = () => {
    return (
       <>
     <SectionContainer className = 'rich-black'>
        <MainHeading className='cultured-white'>Past Work</MainHeading>
            <InfoSec {...Chatbot}/>
            <InfoSec {...VRPT}/>
            <InfoSec {...SASERedesign}/>
            
            <InfoSec {...Marilartoon}/>
    </SectionContainer>
        </>
    )
}

export default Portfolio
