import logo from '../../images/logo.png';
import React, {useState,useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {Nav,NavbarContainer,NavName,MobileIcon,NavMenu,NavItem,ScrollLink,NavLogo, Anchor, NavCenter, NavLeft, NavRight} from './NavBarElements';
import {animateScroll as scroll} from 'react-scroll';
import Pdf from '../../Pdfs/NicoleCiarResume.pdf';
const Navbar = ({toggle}) => {
    const[scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
        setScrollNav(true)
    } else {
        setScrollNav(false)
        }
    }
    useEffect(() => {
    window.addEventListener('scroll',changeNav)
    },[]);
    
    const toggleHome = () => {
        scroll.scrollToTop();
    }
return (
    <>
    <NavbarContainer>    
        <NavLeft> 
            <NavLogo src = {logo} onClick={toggleHome} alt={logo} />
        </NavLeft>

    <NavCenter>
        <NavName onClick={toggleHome}>Nicole Ciar</NavName>
    </NavCenter>

    <MobileIcon onClick={toggle}>
        <FaBars />
    </MobileIcon>
            
    <NavRight>
        <Nav scrollNav = {scrollNav}>
            <NavMenu>
                <NavItem>
                    <ScrollLink smooth to="/#about" >About</ScrollLink>
                </NavItem>

                <NavItem>
                <ScrollLink smooth to="/#skills" >Skills</ScrollLink>
                </NavItem>

                <NavItem>
                <ScrollLink smooth to="/#past-projects">Projects</ScrollLink>
                </NavItem>
               
                <NavItem>
                <ScrollLink smooth to="/#contact">Connect</ScrollLink>
                </NavItem>
           
                <NavItem>
                    <Anchor href = {Pdf} target = '_blank'>Resume</Anchor>
                </NavItem>       
            </NavMenu>  
        </Nav>
     </NavRight> 
</NavbarContainer>       
</>
)
}
export default Navbar
