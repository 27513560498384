import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import {VRPT,Chatbot,Connect,SASERedesign,PortfolioPage} from '../components/InfoSec/Data';
import Skills from '../components/Cards';
import {SectionContainer,MainHeading} from '../components/SectionElements';
import '../App.css';
import InfoSec from '../components/InfoSec';
import Contact from '../components/Contact';
import Aos from "aos";
import "aos/dist/aos.css";

const  Home = () => {
  
   useEffect(() => {
      Aos.init({duration: 2000});
   },[]);


    return (
  
   <div display='flex' flex-direction ='column'>
   
      <HeroSection/>

      <SectionContainer className='cultured-white' id='skills'>
         <MainHeading className='rich-black' >Skills</MainHeading>

         <div data-aos = "fade-up">
         <Skills />
         </div>
      </SectionContainer>

      <SectionContainer className='rich-black' id='past-projects'>
         <MainHeading className='cultured-white' >Past Work</MainHeading>
         <InfoSec {...Chatbot}/>
         <InfoSec {...VRPT}/>
         <InfoSec {...SASERedesign}/>
         <InfoSec {...PortfolioPage}/>
      </SectionContainer>





      <SectionContainer className = 'wisteria' id='contact'>
      <div data-aos = "zoom-in">
      <Contact {...Connect}/>
      </div>
      </SectionContainer>
      </div>
  
    )
}

export default  Home
