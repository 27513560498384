import React from 'react';
import {HeroContainer,HeroBg, HeroContent, HeroH1, HeroP, HeroH2} from './HeroElements';
import '../../App.css'
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const HeroSection = () => {

    useEffect(() => {
        Aos.init({duration: 2000});
     },[]);

    return (
        <HeroContainer id ="about">
            <HeroBg> 
            </HeroBg>
            <HeroContent data-aos = "zoom-out">
               <HeroH2>Hello, I’m Nicole!
                I'm a Experience Designer and Developer.</HeroH2>
                <HeroP>I am passionate about creating beautiful and meaningful user experiences whether it be through frontend development, UX, UI, or XR. 
                    I'm currently working at Universal Parks and Resorts as a UXUI Designer! </HeroP>
          
            </HeroContent>
            
        </HeroContainer>
    );
};

export default HeroSection;
