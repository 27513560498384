import styled from 'styled-components'
import {Link as LinkRouter} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import '../../App.css';


export const Nav = styled.nav`
    background: "transparent";
    height: 80px;
    font-family: "Playfair Display";
    align-items: center;
    justify-content: center;
    display: inline;
    
    @media screen and (max-width: 960px){
        transtion: 0.8s all ease;
}
`

export const NavbarContainer = styled.div`
    background: ${({ scrollNav }) => (scrollNav ? '#100E11' : '#100E11')};
    margin-top: -80px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    display: grid;
    grid-auto-columns: 40% 20% 40%;    
    height: 80px;
    width: 100%;
    padding: 0 24px;
    min-width: 360px;
    overflow: hide;

`

export const NavName = styled(LinkRouter)`
    text-align: center;
    color: ${({theme}) => (theme ? '#C6AAF3' : '#F3AAC7')};
    cursor: pointer;
    font-size: clamp(1.5rem, 2.5vw, 4rem); 
    display: block;
    place-items:center;
    float: none;
    font-family: "Playfair Display";
    font-weight: bold;
    text-decoration: none;
    padding-right: 20px;
    white-space: nowrap;

`


export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width:768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #C6AAF3;
    
}
`

export const NavMenu = styled.ul`
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center; 
    color: #C6AAF3;
    font-family: "Playfair Display";
    overflow: hidden;
    font-size: clamp(13px, 1.5vw, 16px);
`

export const NavItem = styled.li`
    height: 80px;
    align-items: center;
    display: inline;
    top: 50%;
    left: 50%;
    float: left;
    padding: 0.5em;

`;

export const ScrollLink = styled(HashLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    color: #C6AAF3;
    font-family: "Playfair Display";
    font-weight: bold;

    &:hover{
        color: #F5F4F6;
    }
   
`;

export const LinkPage = styled(LinkRouter)`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    color: #C6AAF3;
    font-family: "Playfair Display";
    font-weight: bold;

        &:hover{
            color: #F5F4F6;
        }
        }       
`;


export const Anchor = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    color: #C6AAF3;
    font-family: "Playfair Display";
    font-weight: bold;

    &:hover{
        color: #FFFFFF;
    }
`;


export const NavLogo = styled.img`
    display: flex;
    height: 50px;
    width: 70px;
    align-items: center;
    justify-content: flex-start;
    margin-left: -20px;
`;


export const NavLeft = styled.div`
    grid-column: 1;
    display: flex;
    align-items: center;
    margin-left: 24px; 
`

export const NavCenter = styled.div`
    grid-column: 2;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content:center;
`


export const NavRight = styled.div`
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
        
    @media screen and (max-width:768px){
        display: none;
    }
`