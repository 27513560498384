import React from 'react'
import {SidebarContainer,CloseIcon,Icon,SidebarWrapper, SidebarMenu, SidebarLink, Anchor} from './SidebarElements';
import Pdf from '../../Pdfs/NicoleCiarResume.pdf';


const Sidebar = ({isOpen,toggle}) => {
return (
       <SidebarContainer isOpen={isOpen} onClick={toggle}>
           <Icon onClick={toggle}>
               <CloseIcon />
           </Icon>
            <SidebarWrapper>
               <SidebarMenu>
                   <SidebarLink to="about" onClick={toggle}>
                       About
                   </SidebarLink>
                   <SidebarLink to="skills" onClick={toggle}>
                       Skills
                   </SidebarLink>
                   <SidebarLink to="past-projects" onClick={toggle}>
                       Past Projects
                   </SidebarLink>
                   <SidebarLink to="contact" onClick={toggle}>
                       Contact
                   </SidebarLink>
                   <Anchor href = {Pdf} target = '_blank'>Resume</Anchor>
               </SidebarMenu>
           </SidebarWrapper>
       </SidebarContainer>
    )
}
export default Sidebar

