import ConnectImg from '../../images/headshot.jpg';

import VRPTImg from '../../images/VRforPTThumbnail.jpg';
import BotniteImg from '../../images/BotniteThumbnail.jpg';
import SaSEImg from '../../images/SASERedesignThumbnail.jpg';
import JobTrackerImg from '../../images/JobTrackerThumbnail.jpg';
import MarliartoonImg from '../../images/MarilartoonThumbnail.jpg';


import VRPdf from '../../Pdfs/VRforPTCiar.pdf';
import BotnitePdf from '../../Pdfs/BotniteCaseStudyNicoleCiar.pdf';
import SaSEPdf from '../../Pdfs/SASERedesignCiar.pdf';
import JobTrackerPdf from '../../Pdfs/TrackThatJobReport.pdf';
import MarliartoonPdf from '../../Pdfs/MarliartoonCaseStudyCiar.pdf';





export const Connect= {
    id: 'connect',
    lightText: false,
    lightTextDesc: true,
    headline: 'Let’s Connect!',
    description: 'Interested in working together? Feel free to reach out to me. I’d love to connect whether it be for opportunities or just to chat!',
    imgStart: false,
    img: ConnectImg,
    alt: 'headshot of Nicole Ciar',
    dark: false,
    primary: true,
    darkText:true,
    target: "_parent",
};
export const VRPT = {
    lightText: true,
    lightTextDesc: true,
    topLine: 'Unity 3D Development',
    headline: 'VR Mini-Games for Physical Therapy',
    description: 'Developed 4 sports related mini-games that targeted arm movements to aid Physical Therapy patients.',
    buttonLabel: 'Read Case Study',
    imgStart: true,
    img: VRPTImg,
    alt: 'VR for Physical Therapy Case Study picture',
    dark: true,
    primary: true,
    darkText: false,
    pageLink: VRPdf,
    target: "_blank",
    aosAnimation: "fade-left",
};
export const Chatbot = {
    id: 'chatbot',
    lightText: true,
    lightTextDesc: true,
    topLine: 'User Experience Design',
    headline: 'Chat Bot for E-Sports Gaming',
    description: 'Enhancing the E-Sports gaming experience with a Chatbot.',
    imgStart: true, 
    img: BotniteImg,
    alt: 'ChatBot Case Study Image',
    buttonLabel: 'Read Case Study',
    dark: true,
    primary: true,
    darkText: false,
    pageLink: BotnitePdf,
    target: "_blank",
    aosAnimation: "fade-right",
};
export const JobTracker = {
    id: 'jobTracker',
    lightText: true,
    lightTextDesc: true,
    topLine: 'Frontend Development & User Experience Design',
    headline: 'Job Tracker Application',
    description: 'Improving the Job Tracking Experience',
    imgStart: true, 
    img: JobTrackerImg,
    alt: 'Job Tracker Case Study Image',
    buttonLabel: 'Read Case Study',
    dark: true,
    primary: true,
    darkText: false,
    pageLink: JobTrackerPdf,
    target: "_blank",
    aosAnimation: "fade-left",
};
export const SASERedesign = {
    id: 'sase-redesign',
    lightText: true,
    lightTextDesc: true,
    topLine: 'User Experience & Interface Design',
    headline: 'SASE Website Redesign',
    description: 'Redesigning the website for the Society of Asian Scientists and Engineers',
    imgStart: true, 
    img: SaSEImg,
    alt: 'SASERedesign',
    buttonLabel: 'Read Case Study',
    dark: true,
    primary: true,
    darkText: false,
    pageLink: SaSEPdf,
    target: "_blank",
    aosAnimation: "fade-right",
};

export const Marilartoon = {
    id: 'marilartoon',
    lightText: true,
    lightTextDesc: true,
    topLine: 'UX/UI Designer and Fullstack Web Engineer',
    headline: 'Marilartoon E-Commerce and Portfolio Website',
    description: 'Designing and developing an e-commerce and portfolio website for a small creative business. ',
    imgStart: true, 
    img: MarliartoonImg,
    alt: 'Marilartoon website design case study',
    buttonLabel: 'Read Case Study',
    dark: true,
    primary: true,
    darkText: false,
    target: "_blank",
    aosAnimation: "fade-right",
    pageLink: MarliartoonPdf,
 
};

export const PortfolioPage = {
    id: 'portfolio',
    lightText: true,
    lightTextDesc: true,
    topLine: 'Additional Projects',
    headline: 'Interested in more of my work?',
    description: 'If you want to see more from me feel free to check out my Full Portfolio!',
    imgStart: true, 
    img: MarliartoonImg,
    alt: 'Image of Additional Case Study Examples',
    buttonLabel: 'See Full Portfolio',
    dark: true,
    primary: true,
    darkText: false,
    pageLink: "/portfolio",
    target: "_parent",
    aosAnimation: "fade-left",
};

export const ErrorMessage = {
    id: 'PageNotFound',
    lightText: false,
    lightTextDesc: true,
    topLine: '404 Error',
    headline: 'Oopsies!',
    description: 'Looks like there is nothing here to see.',
    imgStart: true, 
    img: require('../../images/404.svg').default,
    alt: '404 Error',
    buttonLabel: 'Go Home',
    dark: false,
    primary: false,
    darkText: true,
    pageLink: "/",
    target: "_parent",
};



