import React from 'react'
import {SectionContainer} from '../components/SectionElements';
import InfoSec from '../components/InfoSec';
import {ErrorMessage} from '../components/InfoSec/Data';
import '../App.css';

const PageNotFound = () => {
    return (
    <>
        <SectionContainer>
           <InfoSec {...ErrorMessage}/>
        </SectionContainer>
    </>
    )
}
export default PageNotFound
