import './App.css';
import Home from './pages';
import portfolio from './pages/portfolio';
import{BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import React, {useState,useEffect} from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import PageNotFound from './pages/PageNotFound'

import Loading from './components/Loading';


function App() {
  const [isOpen,setIsOpen] = useState(false);
   
  const toggle = () => {
     setIsOpen(!isOpen);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  })

  return (



   
isLoading==true?
        <Loading/>:
        
        <Router>
      <Sidebar isOpen = {isOpen} toggle= {toggle} />
      <Navbar toggle = {toggle}/> 
      <Switch>
        <Route path = '/' component={Home} exact />
        <Route path = '/portfolio' component={portfolio} />
        <Route component = {PageNotFound} />
      </Switch>
      <Footer />  
    </Router>
        
      

  );

 
}
export default App;
