import React from 'react';
import {FooterContainer, FooterWrap, WebsiteRights,} from './FooterElements';

const Footer = () => {
    return (
        <div>
            <FooterContainer>
                <FooterWrap>
                    <WebsiteRights> {new Date().getFullYear()} All rights reserved. – Designed & Coded by Nicole Ciar</WebsiteRights>
                </FooterWrap>
            </FooterContainer>
        </div>
    )
}

export default Footer
