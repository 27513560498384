import styled from 'styled-components';

export const SectionContainer = styled.div`
    padding: 5rem 5rem 5rem 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media only screen and (max-width: 768px) {
      padding: 1rem 1rem 1rem 1rem;
  }
`

export const MainHeading = styled.h1`
    font-family: "Playfair Display";
    background-color: transparent;
    font-size: clamp(32px, 5vw, 64px);
    text-align: center;
    padding: clamp(32px, 5vw, 64px);
    font-weight: 500;
`

export const PresentationArea = styled.div`
  position =  relative;
  width: 100%;
  height: 100%; 
  padding-top: 56.2500%;
  padding-bottom: 48px;
  box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
`

export const iFrame = styled.iframe`
  loading: lazy;
  position: absolute; 
  width: 1000em;
  height: 500em;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
`

export const LoadingContainer = styled.div`
background-color: #100E11;
padding: 5rem 5rem 5rem 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-color: white;


    @media only screen and (max-width: 768px) {
      padding: 1rem 1rem 1rem 1rem;
  }
`