import React from 'react';
import Icon1 from '../../images/code-purple.svg';
import Icon2 from '../../images/ux-purple.svg';
import Icon3 from '../../images/vr-p.svg';
import '../../App.css'

import {SkillsContainer, SkillsWrapper, SkillsIcon, SkillsH2,
SkillsP,SkillsCard} from './SkillsElements';

const Skills = () => {
    return (
        <>
        <SkillsContainer>

            <SkillsWrapper>

                <SkillsCard className = 'rich-black'>
                <SkillsH2>User Experience</SkillsH2>
                    <SkillsIcon src = {Icon2} alt='Image of girl infront of a computer with UX/UI layout. ' />
                    <SkillsP>Wireframing</SkillsP>
                    <SkillsP>Prototyping</SkillsP>
                    <SkillsP>Responsive Mobile, Web, and App Dseign</SkillsP>
                    <SkillsP> User Testing</SkillsP>
                    <SkillsP>Adobe Suite</SkillsP>
                    <SkillsP>Figma and Sketch</SkillsP>
                    <SkillsP>Design Systems</SkillsP>
                    <SkillsP>Motion Design</SkillsP>

                </SkillsCard>


                <SkillsCard className = 'rich-black'>
                <SkillsH2>Software Development</SkillsH2>
                    <SkillsIcon src = {Icon1} alt='Image of girl infront of a web browser with code.' />
                    <SkillsP>Frontend</SkillsP>
                    <SkillsP>Javascript</SkillsP>
                    <SkillsP>HTML</SkillsP>
                    <SkillsP>CSS</SkillsP>
                    <SkillsP>MERN Stack</SkillsP>
                    <SkillsP>C++</SkillsP>
                     <SkillsP>Flutter</SkillsP>
                </SkillsCard>

                <SkillsCard className = 'rich-black'>
                <SkillsH2>Game Design</SkillsH2>
                    <SkillsIcon src = {Icon3} alt='Image of guy sitting and playing VR.'/>
                    <SkillsP>Unity</SkillsP>    
                    <SkillsP>Game Art</SkillsP>
                    <SkillsP>Game Development</SkillsP>
                    <SkillsP>C#</SkillsP>
                    <SkillsP>Android APIs</SkillsP>
             
                </SkillsCard>

                
            </SkillsWrapper>
        </SkillsContainer>

        </>
    )
}

export default Skills
