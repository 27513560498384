import React from 'react'
import {AnchorButton} from '../ButtonElement';
import Aos from "aos";
import "aos/dist/aos.css";

import { Column1, Column2, InfoSecContainer, InfoSecWrapper, ImgWrap,
Img, TextWrapper, TopLine, Heading, Subtitle, BtnWrap} from './InfoSecElements';
import { useEffect } from 'react';


const InfoSec = ({imgStart, topLine, lightText, headline,darkText, description, buttonLabel, img,
    alt,primary, dark, dark2, pageLink,target,aosAnimation}) => {

       useEffect(() => {
        Aos.init({duration: 2000});
     },[]);
  
      

    return (
        <>
        <InfoSecContainer data-aos={aosAnimation}>
            <InfoSecWrapper imgStart = {imgStart}>
                <Column1>
                <TextWrapper>
                        <TopLine>{topLine}</TopLine>
                        <Heading lightText = {lightText}> {headline} </Heading>
                        <Subtitle darkText = {darkText}>{description}</Subtitle>
                    
                    <BtnWrap>
                            <AnchorButton href = {pageLink} target = {target}
                            smooth = {true}
                            duration = {500}
                            spy = {true}
                            exact = "true"
                            offset = {-80}
                            primary = {primary ? 1 : 0}
                            dark = {dark ? 1: 0}
                            dark2 = {dark2 ? 1: 0}
                    >{buttonLabel}</AnchorButton>
                   </BtnWrap>
                   
                </TextWrapper>
                </Column1>
        
                <Column2>
                    <ImgWrap>
                    <Img src = {img} alt = {alt} />
                    </ImgWrap>
                </Column2>
             
            </InfoSecWrapper>
        </InfoSecContainer>
     </>
    )
}

export default InfoSec


