import React from 'react'
import emailjs from 'emailjs-com'
import {InputButton} from '../ButtonElement'
import '../../App.css';
import { FaGithub, FaLinkedin} from "react-icons/fa";
import { IconContext } from "react-icons";
import { Column1, Column2, InfoSecContainer, InfoSecWrapper, ImgWrap,Img, TextWrapper, Heading, Subtitle} from '../InfoSec/InfoSecElements.js';
import {FormContainer, Form, NameInput, EmailInput, SubjectInput, MessageInput, SubmitInput,Input,TextArea, SocialMedia} from './ContactElements'


const Contact = ({imgStart,lightText, headline,darkText, description, img,
    alt}) => {
    
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_17tbrds', 'template_ye535cd', e.target, 'user_7xntDHfMiI5WOW8MJbFnh')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    
          e.target.reset();
      }

    return (
    <>
<IconContext.Provider value ={{color:"#100E11", size:"3em"}}>

<InfoSecContainer>
           <InfoSecWrapper imgStart = {imgStart}>
                <Column1>
                    <TextWrapper>
                        <Heading lightText = {lightText}> {headline} </Heading>
                        <Subtitle darkText = {darkText}>{description}</Subtitle>
                    </TextWrapper>

<Form  onSubmit={sendEmail}>
<FormContainer>
      
<NameInput>
      <Input required type="text" name="name" placeholder= 'Name'/>
      </NameInput>

<EmailInput>
      <Input required type="email" name="email" placeholder= 'Email' />
      </EmailInput>
      
      <SubjectInput>
      <Input required type="subject" name="subject" placeholder='Subject'/>
      </SubjectInput>

<MessageInput>
   <TextArea required name="message" placeholder='Add Message' />
   </MessageInput>

   <SubmitInput>  
       <InputButton type="submit" value="submit"/>
   </SubmitInput>

  <SocialMedia>
      <a href = 'https://github.com/nciar1' target = '_blank' rel="noreferrer">
      <FaGithub/>
        </a>
        <a href = 'https://www.linkedin.com/in/nicoleciar' target = '_blank' rel="noreferrer">
        <FaLinkedin/>
        </a>
  </SocialMedia> 
</FormContainer>
</Form>
    </Column1>
        <Column2>
                <ImgWrap>
                <Img src = {img} alt = {alt} />
                </ImgWrap>
        </Column2>
    </InfoSecWrapper>
</InfoSecContainer>
</IconContext.Provider>
        </>
    )
}
export default Contact
