import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: #100E11;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    width: 100vw;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 2;
}
`;
export const HeroBg = styled.div`
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow:hidden;
    background:#100E11;
`;

export const HeroContent = styled.div`
    z-index:3;
    max-width:100vw;
    position:absolute;
    padding:8px 24px;
    display:flex;
    flex-direction:column;
    align-items:center;
    background:#100E11;
`
export const HeroH1 = styled.h1`
    font-family: "Playfair Display";
    color:#C6AAF3;
    font-size: clamp(32px, 5vw, 64px);
    text-align: center;
    font-weight: 600;
`
export const HeroP = styled.p`
    margin-top: 24px;
    color: #F5F4F6;
    font-size: clamp(12px, 4vw, 32px);
    max-width: 900px;
    text-align: center;
    font-family: "Barlow";
`

export const HeroH2 = styled.h2`
    font-family: "Playfair Display";
    color:#C6AAF3;
    font-size: clamp(64px, 5vw, 208px);
    text-align: center;
    font-weight: 600;
`