import styled from 'styled-components'
import '../../App.css';


export const InfoSecContainer = styled.div`
    padding: 16px;
    padding-bottom: 1em;
    display: block;
    margin: 1em 1em;
    justify-content: center;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;

    @media screen and(max-width: 768px) {
    padding: 1em;
 
`

export const InfoSecWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    grid-gap: 2rem;
    font-size: 12px;
    background-color: #0000;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col2' 'col1'` : `'col1 col1' 'col2 col2'`)};
        justify-content: center;
    }
`
export const Column1 = styled.div`
    grid-area: col1;
`
export const Column2 = styled.div`
    grid-area: col2;
`
export const ImgWrap = styled.div`
    max-width: 1 em;
    display: block;
`
export const Img = styled.img`
width: 100%;
`
export const TextWrapper = styled.div`
    max-width: 1 em;
    padding-top: 0; 

    @media only screen and (max-width: 768px) {
        text-align: center;
    }
   
`
export const TopLine = styled.p`
    color: #A37AEC;
    font-size: clamp(12px, 4vw, 16px);
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-family: "Barlow";  
`
export const Heading = styled.h1 `
    margin-bottom: 24px;
    font-size: clamp(32px, 4vw, 48px);
    line-height: 1.1;
    font-weight: 500;
    color: ${({lightText}) => (lightText ? '#F5F4F6' : '#100E11')};
    font-family: "Playfair Display";  
`
export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 32px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#100E11' : '#F5F4F6')};
    font-size: clamp(16px, 4vw, 18px);
    font-family: "Barlow";  
`
export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        justify-content: center;
    }
`

