import styled from 'styled-components';
import {Link} from 'react-router-dom';
import '../App.css';

export const Button = styled(Link)`
    font-family: "Barlow";
    border-radius: 8px;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#C6AAF3' : '#100E11')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    border-style: solid;
    border-color: ${({primary}) => (primary ? '#C6AAF3' : '#100E11')};;
    cursor: pointer; 
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2 ease-in-out;
        background: ${({primary}) => (primary ? 'cultured-white' : '#100E11')};
        color: ${({dark}) => (dark ? '#C6AAF3' : 'cultured-white')};

    }
    `

    export const InputButton = styled.input`
    font-family: "Barlow";
    border-radius: 8px;
    background-color: transparent;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#F5F4F6' : '#100E11')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    border-style: solid;
    border-color: ${({primary}) => (primary ? '#C6AAF3' : '#100E11')};;
    cursor: pointer; 
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    text-decoration: none;

    &:hover {
        transition: all .5s ease;
        background: ${({primary}) => (primary ? '#F5F4F6' : '#100E11')};
        color: ${({dark}) => (dark ? '#100E11' : '#F5F4F6')};

    }
    `


export const AnchorButton = styled.a`
    font-family: "Barlow";
    border-radius: 8px;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#C6AAF3' : '#100E11')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    border-style: solid;
    border-color: ${({primary}) => (primary ? '#C6AAF3' : '#100E11')};;
    cursor: pointer; 
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    text-decoration: none;

&:hover {
    transition: all .5s ease;
    background: ${({primary}) => (primary ? '#C6AAF3' : '#100E11')};
    color: ${({dark}) => (dark ? '#100E11' : '#F5F4F6')};
}
`