import styled from 'styled-components';

export const Form = styled.form`
display: block;
border-radius: 8px;
`

export const FormContainer = styled.div`
display: grid;
grid-template-columns: auto auto;
grid-gap: 5px;

grid-template-areas: 
'name email'
'subject subject'
'message message'
'submit socialmedia';
`

export const NameInput = styled.div`
  font-size: 12px;
  grid-area: name;
  width: 1fr;
`

export const EmailInput = styled.div`
  font-size: 12px;
  grid-area: email;
  width: 1fr;
`

export const SubjectInput = styled.div`
  font-size: 12px;
  grid-area: subject;
  width: 100%;
`

export const MessageInput = styled.div`
  font-size: 12px;
  grid-area: message;
`

export const SubmitInput = styled.div`
  font-size: 12px;
  grid-area: submit;
  width: 100%;
`

export const SocialMedia = styled.div`
  font-size: 12px;
  grid-area: socialmedia;
  width: 1fr;
  display: flex;
  justify-content: flex-end;
`

export const Input = styled.input`
  width: 100%;
  font-family: 'Barlow';
  font-color: #100E11;
  font-size: 14px;
  outline: none;
  background-color: transparent;
  border-width: 0 0 2px;
  border-color: #100E11;
`

export const TextArea = styled.textarea`
  font-family: 'Barlow';
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  border-width: 0 0 2px;
  border-color: #100E11;
`


