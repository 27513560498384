import styled from 'styled-components';
import '../../App.css'

export const SkillsContainer = styled.div`
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: auto;
    align-content:center;
`


export const SkillsWrapper = styled.div`
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: top;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
`

export const SkillsCard = styled.div`
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    height: 100%;
    width:inherit;
    margin: 1.5em;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const SkillsIcon = styled.img`
    height: 10em;
    width: 100%;
    align-items: top;
    margin-bottom: 1.5rem;
`

export const SkillsH2 = styled.h2`
    font-size: 3vh;
    margin-bottom: 2vw;
    font-family: "Barlow";
    text-transform: uppercase;
    text-align: center;
`

export const SkillsP = styled.p`
    font-size: 1rem;
    font-family: "Barlow";  
    vertical-align: text-top;
    text-align: center;

    

`